<template>
  <div>
    <div class="font-weight-medium"
         :class="{'display-1': $vuetify.breakpoint.lgAndUp,'headline': $vuetify.breakpoint.mdOnly, 'subheading': $vuetify.breakpoint.smAndDown}"
    >
      <span style="font-family:Oswald"> Introduction</span>
    </div>
    <div style="margin-top: 10px" />
    <v-layout align-center
              justify-center
              row
              wrap
    >
      <v-flex xs12
              sm8
              md8
              lg8
              xl8
              class="font-weight-light"
              :class="{'headline': $vuetify.breakpoint.lgAndUp,'body-2': $vuetify.breakpoint.mdOnly, 'body-2': $vuetify.breakpoint.smAndDown}"
      >
        <span class="subhead">M</span>athematically, a vector is an entity that has both magnitude and direction.
        At first, vectors may seem like a purely mathematical concept.
        However, as you will learn in later chapters, vectors are necessary to describe a number of physical quantities such as velocity, force, flux, etc.
        In fact, the vectors are so essential in physics that a vast majority of theories in physics and mathematics cannot be described or formulated
        without the use of vectors.<br>
        This module on vectors consists of four chapters &mdash; an outline of which is given below:
        <h5> Chapter 1 </h5>
        In chapter 1,  we will review vector form in Cartesian components and polar form (magnitude and direction).
        <h5> Chapter 2 </h5>
        In chapter 2, we will discuss the addition and subtraction of two vectors.
        <h5> Chapter 3 </h5>
        In chapter 3, we will talk about the scalar (also known as dot) product of two vectors.
        <h5> Chapter 4 </h5>
        Finally, in chapter 4, we will concluded the module on vectors with a review of vector (also known as cross) product of two vectors.
        <br><br>
      </v-flex>
      <v-flex xs12
              sm4
              md4
              lg4
              xl4
      >
        <v-img class="imgC"
               padding="10px"
               max-height="500px"
               max-width="500px"
               src="/assets/JWGibbs.jpg"
               contain
        />
        <h5 style="text-align:center">
          J W Gibbs: Father of Modern Vector Calculus
        </h5>
      </v-flex>
    </v-layout>
    <div style="margin-top: 10px" />
    <div class="font-weight-medium"
         :class="{'display-1': $vuetify.breakpoint.lgAndUp,'headline': $vuetify.breakpoint.mdOnly, 'subheading': $vuetify.breakpoint.smAndDown}"
    >
      <span style="font-family:Oswald"> Let's get started</span>
    </div>
    <div style="margin-top: 10px" />
    <div class="font-weight-light"
         :class="{'headline': $vuetify.breakpoint.lgAndUp,'body-2': $vuetify.breakpoint.mdOnly, 'body-2': $vuetify.breakpoint.smAndDown}"
    >
      <span class="subhead">E</span>xplore our comprehensive library of interactive lessons on vectors.
    </div>
    <div style="margin-top: 20px" />
    <v-container grid-list-xl text-xs-center>
      <v-layout
        align-center
        justify-center
        row
        wrap
      >
        <v-flex v-for="topic in topics"
                :key="topic.key"
                xs12
                sm6
                md3
                lg3
                xl3
        >
          <subtopic-card
            :headline="topic.headline"
            :description="topic.description"
            :img="topic.img"
            :path="topic.path"
          />
        </v-flex>
      </v-layout>
    </v-container>
    <br>
    <br>
    <br>
  </div>
</template>
<script>
import SubtopicCard from '../../../views/SubTopics.vue'
export default {
    name: 'Vectors',
    components: {
    SubtopicCard
    },
    data () {
      return {
        playerOptions: {
        preload:'auto',
        ratio: '16:9'
        },
      topics: [
      {
        headline: "Vectors", description: "Ohm's law is a fundamental principle that relates the voltage applied across a conducting material to the electric current flowing through it.  ",
        img:"/assets/wdice-1.svg", path: "/mathematics/vectors/cartesian"
      },
      { headline: "Addition of Two Vectors", description: "Kirchhoff’s voltage law can be used to calculate voltage drops across different components of an electric circuit, simple or complex.",
        img: "/assets/wdice-2.svg", path: "/mathematics/vectors/addition"
      },
      { headline: "Dot Product of Two Vectors", description: "A resistor is an Ohmic device that is used to limit the flow of charge in a circuit",
          img: "/assets/wdice-3.svg", path: "/mathematics/vectors/inner"
      },
      {
        headline: "Cross Product of Two Vectors", description: "Most basic type of electric circuit in which a resistor and a capacitor are connected in series. ",
          img: "/assets/wdice-4.svg", path: "/mathematics/vectors/outer"
      }
      ]
    }
  },
  created () {
    this.$store.commit('navigation/resetState');
    this.$store.commit('navigation/changeTitle', 'Vectors');
    this.$store.commit('navigation/changeMenu', 'Vectors');
    let newTopics = [
      {title: 'Vectors', img:'/assets/number-1.svg', action: () => this.$router.push({path: '/mathematics/vectors/cartesian'})},
      {title: 'Sum of Two Vectors', img:'/assets/number-2.svg', action: () => this.$router.push({path: '/mathematics/vectors/addition'})},
      {title: 'Inner Product of Two Vectors',img:'/assets/number-3.svg', action: () => this.$router.push({path: '/mathematics/vectors/inner'})},
      {title: 'Outer Product of Two Vectors', img:'/assets/number-4.svg', action: () => this.$router.push({path: '/mathematics/vectors/outer'})},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newMath =true;
    this.$store.commit('navigation/replaceMath', newMath);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
  },
  metaInfo() {
  return{ title: 'Vectors',
          titleTemplate: '%s - Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {name: 'description', content: 'Learn interactively how to draw a free body diagram'}
                ]
        }
   },
}
</script>
<style scoped lang="scss">
.subhead {
color: #903;
float: left;
font-family: Georgia;
font-size: 75px;
line-height: 60px;
padding-top: 4px;
padding-right: 8px;}
</style>
